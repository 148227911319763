import React from "react";

export default function StoreHours() {
  return (
    <div className="text-right mx-auto" style={{ maxWidth: "300px" }}>
      <div className="d-flex justify-content-between">
        <div>
          <strong>Monday-Thursday:</strong>
        </div>
        <div>
          <strong className="text-primary">2pm - 9pm</strong>
          {/* <br />
          <em>Lunch menu only</em> */}
        </div>
      </div>
      <hr className="m-2" />
      <div className="d-flex justify-content-between">
        <div>
          <strong>Friday-Saturday:</strong>
        </div>
        <div>
          <strong className="text-primary">Noon - 9pm</strong>
          {/* <br />
          <em>Kitchen closes at 9pm</em> */}
        </div>
      </div>
      <hr className="m-1" />
      <div className="d-flex justify-content-between">
        <div>
          <strong>Sunday:</strong>
        </div>
        <div>
          <strong className="text-primary">11am - 7pm</strong>
        </div>
      </div>
    </div>
  );
}
